import "./step-app.scss";

import BlockContent from "@sanity/block-content-to-react";
import * as React from "react";
import { PropsWithChildren } from "react";

type PropsStepApp = PropsWithChildren<{
  title: string | null | undefined;
  text: Record<string, unknown> | null | undefined;
  order: number | null | undefined;
  is_selected: boolean;
}>;

const StepApp: React.FC<PropsStepApp> = (props: PropsStepApp) => {
  return (
    <div className={"m-step-app"}>
      <span className={`a-step-app__number ${props.is_selected ? "a-step-app__number--selected" : ""}`}>
        0{props.order}
      </span>
      <div className="m-step-app__resume">
        <p className={"a-step-app__title"}>{props.title}</p>
        <div className="m-step-app__text">
          <BlockContent blocks={props.text} />
        </div>
      </div>
    </div>
  );
};

export default StepApp;
