import "./iframe-vimeo.scss";

import { PropsWithChildren } from "react";
import * as React from "react";

interface PropsVimeo extends PropsWithChildren<any> {
  code: string;
  width?: number;
  height?: number;
}

const IframeVimeo: (props: PropsVimeo) => JSX.Element = (props: PropsVimeo) => {
  const height: number = props.height ? props.height : 720;
  const width: number = props.width ? props.width : 1280;
  return (
    <div className="embed-iframe">
      <iframe
        width={width}
        height={height}
        className={"a-hero-media"}
        src={`https://player.vimeo.com/video/${props.code}/?autoplay=1`}
        title={"Vídeo"}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        scrolling="no"
        loading="lazy"
        allowFullScreen
      />
    </div>
  );
};

export default IframeVimeo;
