import "./slider-steps-app.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import { convertViewWidthToPx } from "../Slider/helperConvertViewPort";
import StepApp from "./StepApp/StepApp";

const SliderStepsApp: React.FC = () => {
  const { sanityPageApp }: Queries.AppStepsQueryQuery = useStaticQuery(graphql`
    query AppStepsQuery {
      sanityPageApp {
        stepsToUseAppList {
          order
          text: _rawText
          title
        }
      }
    }
  `);
  if (!sanityPageApp || !sanityPageApp.stepsToUseAppList) return <DoNotMountComponent sms={"faltan steps"} />;

  const sliderStepsApp = useRef() as React.MutableRefObject<HTMLDivElement>;
  const totalSlides = sanityPageApp.stepsToUseAppList.length;
  const [positionSlider, setPositionSlider] = useState<number>(1);
  const [is_scrolling, setIsScrolling] = useState(true);

  function setMandatoryX() {
    sliderStepsApp.current.style.scrollSnapType = "x mandatory";
  }

  function setPositionOne() {
    setPositionSlider(1);
  }

  useEffect(() => {
    if (!sliderStepsApp.current) return;
    sliderStepsApp.current.style.scrollSnapType = "unset";
    const firstElement = sliderStepsApp.current.children[0] as HTMLDivElement;
    const nextElement = sliderStepsApp.current.children[positionSlider - 1] as HTMLDivElement;
    const paddingElement = getComputedStyle(firstElement).getPropertyValue("--padding-element-app");
    sliderStepsApp.current.scrollLeft = nextElement.offsetLeft - convertViewWidthToPx(paddingElement);
  }, [positionSlider]);

  useEffect(() => {
    sliderStepsApp.current.addEventListener("touchmove", setMandatoryX, { passive: true });
    window.addEventListener("resize", setPositionOne);
    return () => {
      if (sliderStepsApp.current) {
        sliderStepsApp.current.removeEventListener("touchmove", setMandatoryX);
      }
      window.removeEventListener("resize", setPositionOne);
    };
  }, []);

  const prev = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider < 2) {
      setPositionSlider(totalSlides);
    } else {
      setPositionSlider(positionSlider - 1);
    }
    setIsScrolling(true);
  };

  const next = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider >= totalSlides) {
      setPositionSlider(1);
    } else {
      setPositionSlider(positionSlider + 1);
    }
    setIsScrolling(true);
  };
  return (
    <section className={"o-slider-steps-app"}>
      <div
        ref={sliderStepsApp}
        className={"c-slider-steps-app"}>
        {sanityPageApp.stepsToUseAppList.map((step, index) => {
          return (
            <StepApp
              key={index}
              title={step?.title}
              text={step?.text}
              order={step?.order}
              is_selected={index + 1 === positionSlider}
            />
          );
        })}
      </div>
      <div className="m-slider-steps-app__controls">
        <button
          className={"m-control m-control--left"}
          aria-label={"Seleccionar paso anterior"}
          onClick={prev}>
          {left_svg}
        </button>
        <button
          aria-label={"Seleccionar paso siguiente"}
          className={"m-control  m-control--right"}
          onClick={next}>
          {right_svg}
        </button>
      </div>
    </section>
  );
};

export default SliderStepsApp;
