import "./video.scss";
import "./elements.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { Photo } from "../../types/shared";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import IframeVimeo from "../IframeViemo/IframeVimeo";
import { play_svg, wave_svg } from "./elements_video";

type PropsVideo = PropsWithChildren<{
  code: string | null | undefined;
  photo: Photo | null;
}>;

const enum StatusVideo {
  FACADE,
  LAUNCH,
}

const Video: React.FC<PropsVideo> = (props: PropsVideo) => {
  if (!props.code) return <DoNotMountComponent sms={"falta el video"} />;
  const [statusVideo, setStatusVideo] = useState<StatusVideo>(StatusVideo.FACADE);
  return (
    <section className={"o-video"}>
      <div className={"c-video"}>
        {statusVideo === StatusVideo.LAUNCH && <IframeVimeo code={props.code} />}
        {statusVideo === StatusVideo.FACADE && props.photo && (
          <div className="embed-iframe">
            <div className="m-video__facade">
              <div className="m-video__play">
                <button
                  onClick={() => {
                    setStatusVideo(StatusVideo.LAUNCH);
                  }}
                  className={"m-button-clean"}
                  aria-label={"Play"}>
                  {play_svg}
                </button>
              </div>
              <SanityImage
                {...props.photo.image}
                alt={props.photo.alt}
                className={"a-video__facade"}
                style={{ maxWidth: "1128px" }}
                width={1128}
                height={636}
              />
            </div>
          </div>
        )}
      </div>
      <div className="m-video__element">{wave_svg}</div>
    </section>
  );
};

export default Video;
