import "../styles/layouts/app.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../assets/svg/home_elements";
import AppFunctions from "../components/AppFunctions/AppFunctions";
import BannerDownloadApp from "../components/BannerDownladApp/BannerDownloadApp";
import BrandsFoodServices from "../components/Brands/BrandsFoodServices";
import HeroApp from "../components/Hero/HeroApp/HeroApp";
import Layout from "../components/Layout/layout";
import { SEO } from "../components/SEO/SEO";
import SliderStepsApp from "../components/SliderStepsApp/SliderStepsApp";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import Video from "../components/Video/Video";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Image, Photo } from "../types/shared";

const AppPage = ({ data }: PageProps<Queries.AppPageQuery>) => {
  const alt = data.sanityPageApp?.video?.alt ? data.sanityPageApp?.video?.alt : "";
  const videoThumbnail: Photo = {
    image: data?.sanityPageApp?.video?.image as unknown as Image,
    alt,
  };

  return (
    <Layout>
      <div className="l-app">
        <HeroApp />
        <SliderStepsApp />
        <BrandsFoodServices />
        <BannerDownloadApp />
        <AppFunctions />
        <Video
          code={data.sanityPageApp?.video?.code}
          photo={videoThumbnail}
        />
        <WeKnowYourProblems
          title={data.sanityPageApp?.weKnowYourProblems?.title}
          problemsList={data.sanityPageApp?.weKnowYourProblems?.PAQsList as Problem[]}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default AppPage;

export const Head = ({ data }: PageProps<Queries.AppPageQuery>) => (
  <SEO
    title={data.sanityPageApp?.seo?.title}
    robots={data.sanityPageApp?.seo?.seo_robots}
    description={data.sanityPageApp?.seo?.description}
    canonical={data.sanityPageApp?.seo?.canonical}
    imageAbsolutePath={data.sanityPageApp?.seo?.thumbnail?.asset?.url}
  />
);

export const query = graphql`
  query AppPage {
    sanityPageApp {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      weKnowYourProblems {
        title
        PAQsList {
          question
          answer: _rawAnswer
        }
      }
      video {
        image {
          asset {
            gatsbyImageData
            _id
          }
        }
        alt
        code
      }
    }
  }
`;
