import "./app-functions.scss";

import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { left_svg, right_svg } from "../../assets/svg/controls_elements";
import DoNotMountComponent from "../DoNotMountComponent/DoNotMountComponent";
import Counter from "../Slider/Counter/Counter";
import { convertViewWidthToPx } from "../Slider/helperConvertViewPort";
import FunctionApp from "./FunctionApp/FunctionApp";

const AppFunctions: React.FC = () => {
  const { sanityPageApp: data }: Queries.FunctionsAppQuery = useStaticQuery(graphql`
    query FunctionsApp {
      sanityPageApp {
        advancedFunctions {
          title
          appFunctionsList {
            title
            resume: _rawResume
          }
        }
      }
    }
  `);
  if (
    !data?.advancedFunctions ||
    !data.advancedFunctions.appFunctionsList ||
    data.advancedFunctions.appFunctionsList?.length < 1
  )
    return <DoNotMountComponent sms={"Faltan las funciones avanzadas"} />;

  const totalSlides = data.advancedFunctions.appFunctionsList.length;
  const sliderFunctions = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [positionSlider, setPositionSlider] = useState<number>(1);
  const [is_scrolling, setIsScrolling] = useState(true);

  function setMandatoryX() {
    sliderFunctions.current.style.scrollSnapType = "x mandatory";
  }

  function setPositionOne() {
    setPositionSlider(1);
  }

  useEffect(() => {
    if (!sliderFunctions.current) return;
    sliderFunctions.current.style.scrollSnapType = "unset";
    const firstElement = sliderFunctions.current.children[0] as HTMLDivElement;
    const nextElement = sliderFunctions.current.children[positionSlider - 1] as HTMLDivElement;
    const paddingElement = getComputedStyle(firstElement).getPropertyValue("--padding-element-app");
    sliderFunctions.current.scrollLeft = nextElement.offsetLeft - convertViewWidthToPx(paddingElement);
  }, [positionSlider]);

  useEffect(() => {
    sliderFunctions.current.addEventListener("touchmove", setMandatoryX, { passive: true });
    window.addEventListener("resize", setPositionOne);
    return () => {
      if (sliderFunctions.current) {
        sliderFunctions.current.removeEventListener("touchmove", setMandatoryX);
      }
      window.removeEventListener("resize", setPositionOne);
    };
  }, []);

  const prev = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider < 2) {
      setPositionSlider(totalSlides);
    } else {
      setPositionSlider(positionSlider - 1);
    }
    setIsScrolling(true);
  };

  const next = () => {
    if (is_scrolling) {
      setIsScrolling(false);
    }
    if (positionSlider >= totalSlides) {
      setPositionSlider(1);
    } else {
      setPositionSlider(positionSlider + 1);
    }
    setIsScrolling(true);
  };

  return (
    <section className={"o-app-functions"}>
      <div className={"c-app-functions m-app-functions__head"}>
        <h3 className={"a-app-functions__title"}>{data?.advancedFunctions?.title}</h3>
        <div className="m-app-functions__controls">
          <button
            aria-label={"Deslizar a la función anterior"}
            className={"m-control m-control--left"}
            onClick={prev}>
            {left_svg}
          </button>
          <button
            aria-label={"Deslizar a la función siguiente"}
            className={"m-control  m-control--right"}
            onClick={next}>
            {right_svg}
          </button>
        </div>
      </div>
      <div
        ref={sliderFunctions}
        className="m-app-functions__slider">
        {data.advancedFunctions.appFunctionsList.map((appFunction, index) => {
          return (
            <FunctionApp
              key={index}
              title={appFunction?.title}
              order={index + 1}
              resume={appFunction?.resume}
              is_selected={index + 1 === positionSlider}
            />
          );
        })}
      </div>
      <div className={"c-app-functions m-app-functions__footer"}>
        <Counter
          currentPosition={positionSlider}
          totalPositions={data?.advancedFunctions?.appFunctionsList?.length}
        />
      </div>
    </section>
  );
};

export default AppFunctions;
