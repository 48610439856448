import * as React from "react";
// Remember that all const are suffix _svg

const play_svg = (
  <svg
    role={"button"}
    aria-label={"Play"}
    id={"video-play"}
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70">
    <path
      id="Path_164"
      data-name="Path 164"
      d="M59.749,10.251a35,35,0,0,0-49.5,49.5,35,35,0,0,0,49.5-49.5ZM35,65.67A30.67,30.67,0,1,1,65.67,35,30.7,30.7,0,0,1,35,65.67Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <path
      id="Path_165"
      data-name="Path 165"
      d="M181.062,175.581,204.57,162l-23.508-13.578Z"
      transform="translate(-154.929 -127.003)"
      fill="#fff"
    />
  </svg>
);

const wave_svg = (
  <svg
    id="Group_230"
    data-name="Group 230"
    xmlns="http://www.w3.org/2000/svg"
    width="529.664"
    height="537.715"
    viewBox="0 0 529.664 537.715">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_119"
          width="529.664"
          height="537.716"
          fill="none"
          stroke="#5cb4e1"
          strokeWidth="1"
        />
      </clipPath>
    </defs>
    <g
      id="Group_111"
      clipPath="url(#clip-path)">
      <path
        id="Path_111"
        d="M30.035,81.279c-9.769,13.3-15.475,28.3-13.646,44.991,3.579,32.677,35.873,56.9,38.774,89.649C59.279,262.4,3.745,295.489.63,342.046c-2.2,32.867,23.845,62.327,54.287,74.907,34.292,14.172,76.067,1.838,107.029,20.453,36.227,21.783,34.88,82.4,75.649,95.971,23.316,7.762,48.912-9.153,60.113-31.026s11.934-47.369,13.895-71.869c2.542-31.792,4.181-58.456,35.756-75.576,21.68-11.756,46.87-14.888,70.189-22.919a175.657,175.657,0,0,0,97.4-83.794c16.109-30.831,19.507-75.056-9.065-94.893-17.1-11.867-40.1-10.693-60.232-5.416-38.362,10.056-80.543,28.461-119.153,6.34-32.963-18.887-34.292-62.538-51.874-93.368-15.177-26.609-38.9-49.8-68.553-57.463C164.044-7.47,121.046,14.181,83.8,36.478,64.182,48.225,43.34,63.172,30.035,81.279Z"
        transform="translate(0.962 0.961)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        strokeDasharray="0 2"
      />
      <path
        id="Path_112"
        d="M30.356,80.425c-9.194,12.618-14.613,26.825-13.1,42.587,2.1,22.066,17.663,40.267,27.468,60.162,3.929,7.967,6.933,16.206,7.768,25.075,4.131,43.96-47.112,75.658-46.981,119.261.047,16.542,6.807,30.863,15.943,45.052,8.884,13.8,20.862,25.1,35.879,32.35,30.2,14.59,68.179,5.779,97.8,19.127q2.485,1.117,4.885,2.448c35.411,19.659,36.519,73.134,76,86.234,22.458,7.453,46.709-6.947,57.968-26.051s13.328-41.658,16.281-64.4c3.5-26.767,5.259-50.9,26.437-67.995a70.383,70.383,0,0,1,7.2-5.1c19.27-11.972,41.482-16,62.456-24.675,37.357-15.443,70.855-45.876,91.305-82.938,8.429-15.271,14.783-33.1,15.142-50.673.351-19.229-7.155-37.719-23.278-47.682-17.027-10.523-38.765-9.2-58.05-3.64-31.552,9.1-65.509,24.257-98.4,15.163a73.226,73.226,0,0,1-16.115-6.6c-31.876-17.7-34.7-59.172-52.339-88.425C249.577,34.765,226.719,13.184,198.372,6.07,158.14-4.021,116.973,16.117,81.483,37.421,62.73,48.677,43,63.031,30.356,80.425Z"
        transform="translate(10.585 6.513)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        id="Path_113"
        d="M30.857,79.573c-8.621,11.94-13.749,25.35-12.56,40.185,1.645,20.81,15.589,38.193,24.514,57.1,3.579,7.558,6.345,15.358,7.184,23.725,4.149,41.441-42.429,71.91-39.431,112.4,1.245,16.787,8.794,29.717,17.044,45.114,7.748,14.464,17.511,26.527,32.309,34.783,27.947,15.589,64.7,8.49,93.584,20.5q2.419,1.008,4.768,2.191c34.6,17.535,38.161,63.867,76.356,76.5,21.6,7.146,44.509-4.739,55.821-21.07s14.727-35.946,18.671-56.925c4.7-24.707,6.655-48.377,25.2-65.459a66.977,66.977,0,0,1,6.3-5.151c16.863-12.191,36.1-17.123,54.728-26.434,33.235-16.612,64.732-46.791,85.205-82.076,9.238-15.922,17.146-33.962,18-52.5.841-20.027-7.923-39.849-25.2-49.324-16.877-9.249-37.43-7.707-55.868-1.864-30.173,9.571-62.345,25.1-94.239,16.895a69.816,69.816,0,0,1-15.642-6.106c-30.784-16.518-35.09-55.806-52.8-83.479C239.9,35.283,217.888,15.312,190.85,8.747c-38.438-9.314-77.767,9.308-111.5,29.615C61.454,49.134,42.844,62.891,30.857,79.573Z"
        transform="translate(20.032 12.066)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        id="Path_114"
        d="M31.5,78.719C23.453,89.981,18.612,102.593,19.486,116.5,20.672,136.053,33,152.618,41.046,170.538c3.225,7.146,5.755,14.511,6.6,22.376,4.163,38.92-37.337,68.272-31.882,105.527,2.507,17.12,10.81,28.537,18.14,45.175,6.649,15.1,14.155,27.9,28.745,37.217,25.718,16.425,61.211,11.2,89.36,21.882q2.362.894,4.654,1.934c33.787,15.411,39.8,54.6,76.71,66.756,20.743,6.839,42.309-2.53,53.674-16.092s16.127-30.232,21.064-49.452c5.9-22.65,8.052-45.873,23.971-62.92a66.078,66.078,0,0,1,5.408-5.206c14.488-12.37,30.728-18.251,47-28.189,29.127-17.8,58.7-47.664,79.108-81.218,10.073-16.568,19.559-34.821,20.854-54.325,1.385-20.821-8.63-42.017-27.129-50.963-16.652-8.055-36.092-6.214-53.682-.088-28.8,10.041-59.181,25.94-90.081,18.622a66.053,66.053,0,0,1-15.163-5.609c-29.7-15.332-35.473-52.441-53.265-78.533-14.756-21.637-35.926-40-61.655-46.008C146.828,2.883,109.333,19.991,77.349,39.3,60.322,49.589,42.828,62.753,31.5,78.719Z"
        transform="translate(29.338 17.617)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        id="Path_115"
        d="M32.258,77.866c-7.447,10.6-12.022,22.4-11.47,35.379.73,18.3,11.438,34.044,18.61,50.977a75.755,75.755,0,0,1,6.012,21.023c4.178,36.4-31.83,64.694-24.33,98.662,3.865,17.514,12.86,27.328,19.235,45.236,5.589,15.7,10.807,29.2,25.18,39.651,23.541,17.108,57.726,13.912,85.138,23.258q2.3.78,4.537,1.68c32.972,13.287,41.438,45.327,77.063,57.019,19.884,6.53,40.109-.324,51.526-11.113s17.485-24.532,23.454-41.982c7.05-20.611,9.451-43.39,22.738-60.381a68.345,68.345,0,0,1,4.517-5.265c12.092-12.58,25.356-19.387,39.262-29.942,25.031-19,52.753-48.5,73.011-80.359,10.935-17.2,21.929-35.683,23.711-56.154,1.878-21.616-9.293-44.234-29.054-52.6-16.378-6.933-34.76-4.721-51.5,1.689-27.418,10.512-56.014,26.784-85.918,20.354a63,63,0,0,1-14.689-5.113C260.681,115.735,253.45,80.8,235.562,56.291,220.969,36.305,200.624,19.565,176.2,14.1,141.358,6.337,105.7,21.929,75.47,40.246,59.308,50.045,42.959,62.637,32.258,77.866Z"
        transform="translate(38.524 23.171)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        id="Path_116"
        d="M34.825,77.013c-6.871,9.921-11.16,20.924-10.926,32.978.275,17.038,9.363,31.97,15.656,47.912a79.719,79.719,0,0,1,5.428,19.673c4.2,33.886-25.934,61.112-16.778,91.8,5.349,17.941,14.952,26.1,20.331,45.3,4.566,16.287,7.488,30.439,21.616,42.081,21.432,17.66,54.24,16.626,80.917,24.637q2.235.67,4.423,1.426c32.157,11.163,43.075,36.06,77.414,47.279,19.028,6.223,37.909,1.887,49.379-6.135s18.879-18.823,25.846-34.509c8.242-18.56,10.839-40.933,21.505-57.846,1.136-1.8,2.311-3.588,3.623-5.317,9.7-12.8,19.986-20.541,31.532-31.7,20.935-20.237,46.864-49.294,66.908-79.5,11.829-17.821,24.3-36.542,26.568-57.98,2.375-22.414-9.91-46.519-30.98-54.24-16.059-5.887-33.422-3.231-49.318,3.465-26.039,10.979-52.85,27.626-81.761,22.081A59.508,59.508,0,0,1,282,123.8c-27.518-12.966-36.189-45.73-54.191-68.646C213.4,36.807,193.86,21.691,170.748,16.78,137.7,9.786,103.875,23.865,75.4,41.186,60.1,50.5,44.875,62.5,34.825,77.013Z"
        transform="translate(45.904 28.722)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        id="Path_117"
        d="M37.866,76.16c-6.293,9.244-10.3,19.451-10.38,30.573-.181,15.785,7.286,29.9,12.7,44.854a87.184,87.184,0,0,1,4.841,18.324c4.21,31.365-19.691,57.472-9.229,84.928,6.994,18.35,17.088,24.862,21.432,45.359,3.573,16.869,4.21,31.608,18.049,44.515,19.4,18.093,50.752,19.34,76.7,26.013,1.449.374,2.886.76,4.306,1.169,31.345,9.042,44.716,26.793,77.77,37.541,18.166,5.916,35.7,4.1,47.229-1.154s20.278-13.118,28.236-27.038c9.439-16.509,12.212-38.5,20.275-55.307.871-1.817,1.744-3.62,2.726-5.373,7.3-13.021,14.613-21.718,23.8-33.457,16.834-21.508,41.032-50.057,60.811-78.638,12.749-18.423,26.661-37.4,29.425-59.806,2.86-23.211-10.494-48.888-32.9-55.879-15.715-4.905-32.09-1.738-47.135,5.238-24.663,11.452-49.686,28.467-77.6,23.81a56.408,56.408,0,0,1-13.734-4.119c-26.431-11.779-36.522-42.388-54.655-63.7-14.216-16.711-32.957-30.194-54.76-34.555C134.515,13.24,102.525,25.8,75.8,42.128,61.367,50.954,47.259,62.362,37.866,76.16Z"
        transform="translate(52.808 34.275)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        id="Path_118"
        d="M40.94,75.307c-5.72,8.566-9.413,17.976-9.837,28.172-.6,14.529,5.215,27.821,9.752,41.792a101.8,101.8,0,0,1,4.254,16.971c4.227,28.847-13.164,53.729-1.677,78.062,8.82,18.686,19.261,23.623,22.528,45.42,2.615,17.447,1,32.709,14.485,46.948,17.462,18.441,47.267,22.051,72.471,27.389,1.411.3,2.808.6,4.192.914,30.532,6.915,46.355,17.523,78.124,27.8,17.31,5.609,33.5,6.3,45.085,3.824s21.677-7.409,30.626-19.568c10.64-14.456,13.55-36.086,19.039-52.768.6-1.835,1.18-3.652,1.835-5.425a230.88,230.88,0,0,1,16.068-35.216c12.7-22.814,35.248-50.784,54.714-77.779,13.7-19,29.022-38.263,32.28-61.629,3.348-24.012-11.052-51.351-34.83-57.521-15.355-3.985-30.752-.245-44.953,7.015-23.284,11.923-46.519,29.311-73.44,25.543a53.452,53.452,0,0,1-13.258-3.626c-25.341-10.593-36.834-39.06-55.117-58.757-14.006-15.087-31.967-26.924-52.461-30.734-29.457-5.446-59.613,5.6-84.58,20.935C62.667,51.409,49.676,62.224,40.94,75.307Z"
        transform="translate(59.682 39.826)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        id="Path_119"
        d="M44.071,74.455c-5.151,7.885-8.554,16.5-9.3,25.77-1.063,13.272,3.141,25.744,6.8,38.727a135.455,135.455,0,0,1,3.666,15.621c4.245,26.331-6.421,49.835,5.875,71.2,10.868,18.882,21.47,22.387,23.623,45.479,1.683,18.031-2.115,33.749,10.921,49.382,15.621,18.724,43.781,24.763,68.249,28.768q2.055.333,4.078.657c29.715,4.791,47.994,8.253,78.474,18.064,16.451,5.3,31.3,8.51,42.937,8.8s23.083-1.7,33.016-12.1c11.847-12.4,14.853-33.688,17.809-50.229.33-1.852.611-3.684.941-5.484,2.477-13.486,3.8-24.12,8.338-36.969,8.519-24.143,29.5-51.483,48.614-76.92,14.689-19.545,31.342-39.133,35.14-63.455,3.789-24.818-11.6-53.925-36.758-59.16-14.993-3.12-29.419,1.245-42.771,8.791-21.905,12.393-43.355,30.153-69.28,27.269a50.676,50.676,0,0,1-12.782-3.129c-24.254-9.407-37.126-35.75-55.581-53.811-13.775-13.483-30.977-23.658-50.162-26.919-27.661-4.669-55.979,4.864-79.2,19.2C64.022,51.866,52.146,62.083,44.071,74.455Z"
        transform="translate(66.498 45.379)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        id="Path_120"
        d="M47.309,73.6a56.626,56.626,0,0,0-8.75,23.366c-1.545,12.016,1.066,23.673,3.848,35.669,1.1,4.686,2.217,9.428,3.085,14.271,4.257,23.81.45,45.742,13.424,64.331C72.077,230.095,82.63,232.4,83.635,256.783c.771,18.622-5.127,34.734,7.356,51.813,13.871,18.975,40.3,27.474,64.027,30.144q1.989.223,3.959.4c28.905,2.67,49.633-1.011,78.831,8.326,15.592,4.993,29.1,10.722,40.79,13.781s24.491,4.02,35.409-4.625c13.053-10.339,16.106-31.28,16.574-47.69.053-1.873.041-3.716.05-5.539.05-13.716-1.645-25.324.6-38.724,4.277-25.47,23.781-52.149,42.517-76.061,15.718-20.056,33.705-40,37.991-65.281,4.283-25.616-12.142-56.621-38.681-60.8-14.634-2.3-28.081,2.74-40.585,10.567-20.529,12.863-40.188,30.994-65.123,29a48.147,48.147,0,0,1-12.305-2.632C231.886,91.236,217.655,66.985,199,50.592c-13.532-11.9-29.989-20.392-47.866-23.1-25.861-3.9-52.347,4.122-73.812,17.462C65.486,52.319,54.718,61.939,47.309,73.6Z"
        transform="translate(73.206 50.93)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        id="Path_121"
        d="M50.776,72.751a60.643,60.643,0,0,0-8.2,20.965c-2,10.757-1.011,21.6.894,32.607.748,4.277,1.624,8.58,2.5,12.919,4.274,21.292,7.362,41.412,20.976,57.466,15.7,18.514,25.984,19.951,25.817,45.6-.126,19.226-8.017,35.677,3.789,54.243,12.226,19.226,36.811,30.188,59.806,31.523q1.928.114,3.842.146c28.093.546,51.275-10.281,79.184-1.414,14.736,4.686,26.9,12.931,38.643,18.762s25.911,9.758,37.8,2.846c14.277-8.25,17.327-28.847,15.344-45.155-.231-1.884-.532-3.745-.847-5.592-2.384-13.941-7.117-26.513-7.125-40.483-.012-26.764,18.087-52.783,36.419-75.2,16.787-20.529,36.069-40.857,40.845-67.107,4.777-26.413-12.7-59.449-40.6-62.441-14.295-1.531-26.749,4.23-38.406,12.343C302.3,78.118,284.425,96.62,260.486,95.512a45.768,45.768,0,0,1-11.829-2.136c-22.075-7.041-37.638-29.232-56.508-43.925A94.265,94.265,0,0,0,146.583,30.17C122.516,27.05,97.864,33.553,78.156,45.9,67.18,52.777,57.5,61.781,50.776,72.751Z"
        transform="translate(79.687 56.481)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        id="Path_122"
        d="M45.043,120a102.141,102.141,0,0,0,30.439,62.169c18.452,17.766,28.28,18.75,26.916,45.663-1.008,19.843-10.772,36.6.225,56.68,10.675,19.5,33.323,32.9,55.581,32.9,28.68,0,55.432-20.041,83.266-11.262,13.877,4.376,24.7,15.139,36.5,23.74s27.307,15.472,40.188,10.319c15.475-6.194,18.522-26.369,14.111-42.616s-14.047-31.4-16.594-47.889c-4.333-28.008,12.41-53.384,30.319-74.337s38.435-41.719,43.7-68.933-13.278-62.418-42.531-64.083c-36.963-2.1-56.125,46.931-93.023,46.577C227.6,88.677,208.247,63.5,185.816,48.314,143.13,19.415,78.956,30.963,54.763,71.9,46.265,86.276,43.32,103.142,45.043,120Z"
        transform="translate(85.644 62.031)"
        fill="none"
        stroke="#5cb4e1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

export { play_svg, wave_svg };
