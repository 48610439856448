import "./function-app.scss";

import BlockContent from "@sanity/block-content-to-react";
import * as React from "react";
import { PropsWithChildren } from "react";

type PropsFunctionApp = PropsWithChildren<{
  title: string | null | undefined;
  order: number | null | undefined;
  resume: Record<string, unknown> | null | undefined;
  is_selected: boolean;
}>;

const FunctionApp: React.FC<PropsFunctionApp> = (props: PropsFunctionApp) => {
  return (
    <div className={"m-function-app"}>
      <div className="m-function-app__head">
        <h3 className={"m-function-app__title"}>{props.title}</h3>
        <span className={`a-function-app__number ${props.is_selected ? "a-function-app__number--selected" : ""}`}>
          0{props.order}
        </span>
      </div>
      <div className="m-function-app__resume">
        <BlockContent blocks={props.resume} />
      </div>
    </div>
  );
};

export default FunctionApp;
