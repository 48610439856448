import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import Brands, { Brand } from "./Brands";

const BrandsFoodServices: React.FC = () => {
  const { sanityDocumentModules: modules } = useStaticQuery(graphql`
    query foodServicesBrands {
      sanityDocumentModules {
        brandsFoodServicesList {
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
          alt
        }
      }
    }
  `);
  const brandsFoodServicesList = modules.brandsFoodServicesList as Brand[];
  return <Brands brandList={brandsFoodServicesList} />;
};

export default BrandsFoodServices;
