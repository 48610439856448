import "./banner-download-app.scss";

import BlockContent from "@sanity/block-content-to-react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";

import { right_svg } from "../../assets/svg/controls_elements";

const BannerDownloadApp: React.FC = () => {
  const { sanityDocumentModules, sanityDocumentData } = useStaticQuery(graphql`
    query DownloadBannerApp {
      sanityDocumentModules {
        downloadOurApp {
          title
          text: _rawText
          photo {
            alt
            image {
              asset {
                _id
                gatsbyImageData
              }
            }
          }
        }
      }
      sanityDocumentData {
        linkAppStore
        linkGooglePlay
      }
    }
  `);

  const { title, photo, text } = sanityDocumentModules.downloadOurApp;
  return (
    <section className={"o-banner-download-app"}>
      <div className={"c-banner-download-app"}>
        <div className="m-banner-download-app__first">
          <h2 className={"a-title"}>{title}</h2>
          <StaticImage
            src={"../Summary/usicom.jpg"}
            alt={"Logotipo app Usicom"}
            className={"a-banner-download-app__icon"}
          />
          <BlockContent blocks={text} />
          <div className="m-banner-download-app__ctas">
            <div className="m-banner-download-app__stores">
              <a
                href={sanityDocumentData.linkAppStore}
                rel={"noopener nofollow noreferrer"}
                title={"Descarga de la app para iOs"}
                target={"_blank"}>
                <StaticImage
                  src={"../../assets/icons/apple-store.png"}
                  alt={"Link de descarga de la app para apple store"}
                  width={147.12}
                  height={43.6}
                />{" "}
              </a>
              <a
                href={sanityDocumentData.linkGooglePlay}
                title={"Descarga de la app para android"}
                rel={"noopener nofollow noreferrer"}
                target={"_blank"}>
                <StaticImage
                  src={"../../assets/icons/google-play.png"}
                  alt={"Logotipo GooglePlay"}
                  width={133.3}
                  height={43.6}
                />{" "}
              </a>
            </div>
            <div className="m-banner-download-app__cta-info">
              <Link
                to={"/app-usicom/"}
                className={"m-button m-button--sea"}>
                Más información {right_svg}
              </Link>
            </div>
          </div>
        </div>
        <div className="m-banner-download-app__second">
          <SanityImage
            className={"a-banner-download-app__photo"}
            {...photo.image}
            width={475}
            height={704}
            alt={photo.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default BannerDownloadApp;
