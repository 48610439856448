import "./brands.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { PropsWithChildren, useCallback, useEffect, useRef } from "react";

export type Brand = {
  alt: string;
  image: any;
};

type PropsBrands = PropsWithChildren<{ brandList: Brand[] }>;

const Brands: React.FC<PropsBrands> = (props: PropsBrands) => {
  if (props.brandList.length === 0) {
    return <></>;
  }
  const sliderBrands = useRef() as React.MutableRefObject<HTMLDivElement>;
  const intervalID = useRef(0);

  const calculateFitClass = () => {
    if (!sliderBrands.current || !sliderBrands.current.children || sliderBrands.current.children.length === 0) {
      return;
    }
    const is_fit = sliderBrands.current.offsetWidth === sliderBrands.current.scrollWidth;
    if (is_fit) {
      sliderBrands.current.classList.add("c-brands--fit");
      if (intervalID.current > 0) {
        clearInterval(intervalID.current);
        intervalID.current = 0;
      }
    } else {
      sliderBrands.current.classList.remove("c-brands--fit");
      if (intervalID.current === 0) {
        intervalID.current = setInterval(() => {
          next();
        }, 0) as unknown as number;
      }
    }
  };

  function handlerIntersect(entries: IntersectionObserverEntry[], _observer: IntersectionObserver) {
    entries.forEach((entry, _index) => {
      if (entry.isIntersecting) {
        calculateFitClass();
      }
    });
  }

  function createObserver() {
    const options = {
      root: null,
      rootMargin: "0px",
      delay: 0,
      threshold: 1,
    };
    const observer = new IntersectionObserver(handlerIntersect, options);
    observer.observe(sliderBrands.current);
  }
  useEffect(() => {
    calculateFitClass();
    createObserver();
    // calculateFitClass();
    window.addEventListener("resize", calculateFitClass);
    return () => {
      window.removeEventListener("resize", calculateFitClass);
    };
  }, []);

  const next = useCallback(() => {
    if (!sliderBrands.current || !sliderBrands.current.children || sliderBrands.current.children.length === 0) return;
    const firstBrand = sliderBrands.current.children[0] as HTMLElement;
    const SecondBrand = sliderBrands.current.children[1] as HTMLElement;
    sliderBrands.current.style.transition = `6000ms linear all`;
    const nextBrand = SecondBrand.offsetLeft;
    sliderBrands.current.style.transform = `translateX(-${nextBrand}px)`;
    const transition = () => {
      sliderBrands.current.style.transition = "none";
      sliderBrands.current.style.transform = "translateX(0)";
      sliderBrands.current.appendChild(firstBrand);
      sliderBrands.current.removeEventListener("transitionend", transition);
    };
    sliderBrands.current.addEventListener("transitionend", transition);
  }, []);

  return (
    <div className={"o-brands"}>
      <div
        className={"c-brands"}
        ref={sliderBrands}>
        {props.brandList.map((brand, index) => {
          return (
            <div
              key={index}
              className={"m-brand"}>
              <SanityImage
                className={"a-brand"}
                {...brand.image}
                alt={`${brand.alt}`}
                style={{
                  maxWidth: "300px",
                }}
                height={brand.image.height}
                width={brand.image.width}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Brands;
